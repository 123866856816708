import { PaymentRequest } from '@stripe/stripe-js'

import { IEventCollectionMenu, IEventDetails, IEventTicket } from '../backendControlled/structure'
import { IMapSeat } from '../map'
import { CollectionEvent, ITypeTicketCollection } from './collection'

export enum CheckoutAuthType {
  fastCheckout = 'fastCheckout',
  login = 'login',
}

export interface ICheckoutAuth {
  email?: string
  type: CheckoutAuthType
}

export enum CheckoutStep {
  select = 'select',
  auth = 'auth',
  external = 'external',
  transaction = 'transaction',
  satispay = 'satispay',
  transactionError = 'transactionError',
  initError = 'initError',
  timeoutError = 'timeoutError',
}

export enum CouponCodeStatus {
  unchecked = 'unchecked',
  valid = 'valid',
  invalid = 'invalid',
  used = 'used',
  expired = 'expired',
}

export interface ITypeTicketAmount {
  amount: string
  currency: string
  formatted: string
}

export interface ITypeTicket {
  description: string
  endSaleDatetime: string
  endSaleDatetime_tz: string
  endSaleDatetime_utc: string
  id: number
  price: ITypeTicketAmount
  presale: ITypeTicketAmount
}

export enum TicketSelectionType {
  automatic = 'automatic',
  manual = 'manual',
}

export interface ITicketSelection<T extends TicketSelectionType> {
  type: T
}

export interface ITicketSelectionAutomatic extends ITicketSelection<TicketSelectionType.automatic> {}

export interface ITicketSeat extends IMapSeat {
  selectedTypeTicketId?: number
  typeTickets: Array<ITypeTicket>
}

export interface ITicketSelectionManual extends ITicketSelection<TicketSelectionType.manual> {
  ticketSeats?: Array<ITicketSeat>
}

export enum PaymentMethod {
  none = 'none',
  cartaMeritoCultura = 'CARTA_MERITO_CULTURA',
  creditCard = 'CREDIT_CARD',
  payPal = 'PAYPAL',
  diciottoApp = 'DICIOTTO_APP',
  voucher = 'VOUCHER',
  scalaPay = 'SCALAPAY',
  applePay = 'APPLE_PAY',
  satisPay = 'SATISPAY',
  list = 'LIST',
}

export enum PromoCodeUrlStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}

export enum PaymentBox {
  methodsList = 'methodsList',
  creditCard = 'creditCard',
  ccRecap = 'ccRecap',
  voucher = 'voucher',
  voucherRecap = 'voucherRecap',
}

export enum PaymentGateway {
  cartaMeritoCultura = 'CARTA_MERITO_CULTURA',
  payPal = 'PAYPAL',
  diciottoApp = 'DICIOTTO_APP',
  voucher = 'VOUCHER',
  scalaPay = 'SCALAPAY',
  satisPay = 'SATISPAY',
  creditCard = 'CREDIT_CARD',
  virtualPay = 'VIRTUAL_PAY',
  list = 'LIST',
}

export enum CardGateway {
  virtualPay = 'VIRTUAL_PAY',
  stripe = 'STRIPE',
}

export enum ICCBrand {
  visa = 'visa',
  mastercard = 'mastercard',
}

interface ICheckoutMetadata {
  parentCodeUrl?: string
}

export interface ICheckoutDetails {
  metadata: ICheckoutMetadata
  eventDetails?: IEventDetails
  typeTicketCollection?: ITypeTicketCollection
  eventTicket: IEventTicket
  eventCollection?: IEventCollectionMenu
  hasMap: boolean
  initialSeats?: Array<IMapSeat>
  initialTypeTicketId?: number
  insideIFrame?: boolean
  needAuth: boolean
  purchaseQuantityLimit?: number
  transactionCode?: string
  transactionErrorMessage?: string
  transactionStarted?: boolean
}

export interface ICheckoutProcess {
  processingStarted?: boolean
  data?: ICheckoutProcessData
  metadata?: ICheckoutProcessMetadata
  error?: string
  status?: number
}

export interface ICheckoutPayment {
  couponCode?: string
  couponCodeStatus?: CouponCodeStatus
  couponCodeError?: string
  couponCodeAmount?: string
  gateway?: PaymentGateway
  cardGateway?: CardGateway
  gatewayData?: Record<string, string>
  methodsAvailable: Array<PaymentMethod>
  methodCurrent?: PaymentMethod
}

export interface ICheckoutCartTicket {
  quantity: number
  seatId?: string
  mapSectionId?: string
  typeTicketId: number
}

export interface ICheckoutCC {
  description: string
  expiry: string
  brand: string
}

export interface IVoucherRecap {
  amount: number
  invalid?: boolean
}

export interface ICheckoutPromoCodeUrlData {
  status: string
  quantityLimit: number
}

export interface ICheckoutAdditionalTermsAndConditionsData {
  title: string
  isRequired: boolean
  url: string
  description: string
}

export interface ICheckoutExtraDetailsData {
  promoCodeUrl?: ICheckoutPromoCodeUrlData
  additionalTermsAndConditions?: ICheckoutAdditionalTermsAndConditionsData
}

export interface ICheckoutStripeData {
  paymentMethod?: string
  paymentIntent?: string
  // paymentRequest is set when paymentMethod is applePay
  paymentRequest?: PaymentRequest
}

export interface ICheckoutCart {
  tickets: Array<ICheckoutCartTicket>
  lastTypeTicket?: number
}

export interface ICheckoutRecapTicket {
  title: string
  quantity: string
  currency: string
  symbol: string
  amount: number
  typeTicketId?: number
}

export interface ICheckoutRecapAmount {
  currency: string
  amount: number
  symbol: string
}

export interface ICheckoutInstallments {
  dueDate: string
  installment: ICheckoutRecapAmount
}

export interface ICheckoutRecapVoucherAmountDetails {
  amount: number
  currency: string
  formatted: string
}

export interface ICheckoutRecapVoucherDetails {
  code: string
  status: string
  amountAvailable: ICheckoutRecapVoucherAmountDetails
}

export interface ICheckoutRecapExtra {
  installments?: Array<ICheckoutInstallments>
  voucherDetails?: ICheckoutRecapVoucherDetails
}

export interface ICheckoutRecap {
  tickets: ICheckoutRecapTicket[]
  subtotal: ICheckoutRecapAmount
  total: ICheckoutRecapAmount
  commission: ICheckoutRecapAmount
  extra?: ICheckoutRecapExtra
}

export interface ICheckoutTransaction {
  transactionCode: string
  paymentMethod: string
  paymentData: object
  authTokenFromQuery?: string
}

export interface ICheckoutSeason {
  events: CollectionEvent[]
}

export interface ICheckout {
  eventCodeUrl?: string
  eventTypeTicketCollectionId?: string
  auth?: ICheckoutAuth
  cart: ICheckoutCart
  details?: ICheckoutDetails
  payment?: ICheckoutPayment
  process?: ICheckoutProcess
  recap?: ICheckoutRecap
  step?: CheckoutStep
  stripe?: ICheckoutStripeData
  extraDetails?: ICheckoutExtraDetailsData
  ticketSelection?: ITicketSelection<TicketSelectionType>
  season?: ICheckoutSeason
}

export interface ICartItem {
  id: number
  quantity: number
}

export interface ICheckoutProcessData {
  cartItems: ICartItem[]
  value: number
  fbPixelId: string
  transactionToken: string
}

export interface ICheckoutProcessMetadata {
  token?: string
}

export interface ICheckoutSeasonItem {
  coverUrl: string
  title: string
  date: Date
}

export type TicketSelectionMethod = 'manual' | 'automatic'

export type TermsAndConditions = 'ticketsms' | 'organizer'

export const Checkouts = {
  getCartQuantity: (cart: ICheckoutCart) =>
    cart.tickets?.reduce<number>((acc, ticket) => acc + ticket.quantity, 0) ?? 0,
}

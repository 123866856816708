import css from './SeasonList.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionSeasonTicketsList } from 'meta/pages/collection'

import { Grid, GridColumn } from 'components/Layout/Grid'
import { MockCollectionAnchorable } from 'components/Pages/Collection/mock'

import SeasonListItem from './SeasonListItem'

const SeasonList: React.FC<CollectionSeasonTicketsList & MockCollectionAnchorable> = (props) => {
  const { collectionId, seasonTickets, anchorId } = props

  const { t } = useTranslation()

  return (
    <Grid>
      <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
        <div className={css.seasonListContainer}>
          <h2 className={css.title} id={anchorId}>
            {t('collection:season_tickets_title')}
          </h2>
          {seasonTickets.map((elem, key) => (
            <SeasonListItem {...elem} collectionId={collectionId} key={key} />
          ))}
        </div>
      </GridColumn>
    </Grid>
  )
}

export default SeasonList

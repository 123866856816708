import { useCallback } from 'react'

import { ICheckoutProcessData } from 'meta/pages/checkout'
import { QueryParams } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'
import { AnalyticsEventType, trackFbPixelEvent } from 'utils/analytics/track'
import { CookieClient, CookieName } from 'utils/cookie'

import { useCheckoutProcess } from 'hooks/useCheckout'
import { useFacebookPixelId, useGoogleAnalyticsId, useProxyApiBase, useS2SToken } from 'hooks/useEnv'
import { useQueryParams } from 'hooks/useQueryParams'

const getGA4AddToCartData = (data: ICheckoutProcessData & { event: any; transactionCode: string }) => {
  const { cartItems, value, event, transactionCode } = data

  return {
    'transaction_id': transactionCode,
    'items': cartItems.map((x) => {
      return {
        item_id: x.id,
        item_name: event && event.name ? event.name : 'Evento Sconosciuto',
        quantity: x.quantity,
      }
    }),
    'currency': 'EUR',
    'num_items': cartItems.reduce<number>((total, { quantity }) => total + quantity, 0),
    'value': (value / 100).toFixed(2),
  }
}

export const useTrackProcess = () => {
  const ticketsmsFbPixelId = useFacebookPixelId()
  const proxyApiBase = useProxyApiBase()
  const googleAnalytics4Id = useGoogleAnalyticsId()
  const checkoutProcess = useCheckoutProcess()
  const { transactionCode } = useQueryParams<QueryParams['CheckoutProcess']>()
  const s2sToken = useS2SToken()

  return useCallback(() => {
    if (!checkoutProcess?.data || !checkoutProcess.data.cartItems) return

    const { data } = checkoutProcess
    const { cartItems, fbPixelId: orgFbPixel, value } = data

    //@ts-ignore
    if (window && window.fbq) {
      //@ts-ignore
      window.fbq('init', orgFbPixel ? orgFbPixel : ticketsmsFbPixelId)

      const lastTransaction = CookieClient.get(CookieName.LAST_TRANSACTION)

      if (lastTransaction !== transactionCode) {
        trackFbPixelEvent(
          AnalyticsEventType.Purchase,
          {
            'content_ids': cartItems.map((x) => x.id),
            'content_name': `Event purchase completed (${transactionCode})`,
            'contents': cartItems.map((x) => ({ id: x.id, quantity: x.quantity })),
            'currency': 'EUR',
            'num_items': cartItems.reduce<number>((total, { quantity }) => total + quantity, 0),
            'value': (value / 100).toFixed(2),
          },
          {
            route: ApiEndpoint.analytics.transaction(transactionCode),
            fromOrganizer: orgFbPixel !== ticketsmsFbPixelId,
            s2sToken: s2sToken,
          }
        )

        import('react-ga4')
          .then((module) => module.default)
          .then((ReactGA) => {
            ReactGA.initialize(googleAnalytics4Id)
            //@ts-ignore
            const ga4AddToCartData = getGA4AddToCartData({ ...data, transactionCode })
            ReactGA.event('purchase', ga4AddToCartData)
          })

        //@ts-ignore
        window.ttq &&
          //@ts-ignore
          window.ttq.track('PlaceAnOrder', {
            content_type: 'product',
            description: transactionCode,
            contents: cartItems.map((x) => ({
              content_id: x.id,
              quantity: x.quantity,
            })),
            currency: 'EUR',
            value: (value / 100).toFixed(2),
          })

        CookieClient.set(CookieName.LAST_TRANSACTION, transactionCode)
      }
    }
  }, [checkoutProcess, googleAnalytics4Id, proxyApiBase, ticketsmsFbPixelId, transactionCode])
}

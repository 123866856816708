import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EnvType } from 'meta/app'
import { IEventDetails } from 'meta/backendControlled/structure'
import { ApiEndpoint } from 'controller/endpoint'
import { AnalyticsEventType, trackFbPixelEvent } from 'utils/analytics/track'
import { Dates } from 'utils/dates'

import MetaOGImages from 'components/BackendControlled/Component/common/MetaOGImages'
import Head from 'components/common/Head'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useFacebookPixelId, useGoogleAnalyticsId, useS2SToken } from 'hooks/useEnv'
import { useAppEnv } from 'hooks/useEnv'
import { useEventMetadata, useEventParentCodeUrl, useIsEventEnded } from 'hooks/useEvent'

import { IPropsComponent } from '../../index'

const EventHead: React.FC<IPropsComponent<IEventDetails>> = (props) => {
  const { datetime, fbPixel: orgFbPixel, location, name, id, codeUrl } = props

  const { t } = useTranslation()

  const language = useCurrentLanguage()
  const parentCodeUrl = useEventParentCodeUrl({ codeUrl, language })

  const formattedDate = useMemo<string>(
    () => Dates.format(new Date(datetime), t('dates:common:eventDate')),
    [datetime, t]
  )
  const dayTitle = Dates.format(new Date(datetime), t('dates:common:dayMonthYear'))

  const title = t('event:meta_title', {
    title: `${name} - ${location?.common} - ${location?.name} - ${dayTitle} - TicketSms`,
  })

  const isPrEvent = parentCodeUrl && parentCodeUrl !== codeUrl

  const appEnv = useAppEnv()
  const googleAnalytics4Id = useGoogleAnalyticsId()
  const ticketsmsFbPixelId = useFacebookPixelId()

  const description = String(
    t('common:head_description_event_date', { name, city: location?.common, date: formattedDate })
  )
  const isPixelFromOrganizer = orgFbPixel !== ticketsmsFbPixelId

  const s2sToken = useS2SToken()

  const isEventEnded = useIsEventEnded()

  const eventMetadata = useEventMetadata()

  const robots = appEnv === EnvType.production && !isPrEvent && !isEventEnded ? 'index, follow' : 'noindex, nofollow'

  const fbPixelCallback = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window && window.fbq && orgFbPixel) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq('init', orgFbPixel)
    }

    trackFbPixelEvent(
      AnalyticsEventType.ViewContent,
      {
        content_name: name,
        content_ids: [id],
        content_type: 'product',
        value: 1,
        currency: 'EUR',
      },
      {
        route: ApiEndpoint.analytics.event(codeUrl),
        fromOrganizer: isPixelFromOrganizer,
        s2sToken: s2sToken,
      }
    )
  }

  const ga4Callback = () => {
    import('react-ga4')
      .then((module) => module.default)
      .then((ReactGA) => {
        ReactGA.initialize(googleAnalytics4Id)

        ReactGA.event('view_item', {
          currency: 'EUR',
          value: 1,
          items: [
            {
              item_id: id,
              item_name: name,
            },
          ],
        })
      })
  }

  const tiktokPixelCallback = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window && window.ttq) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ttq.track('ViewContent', {
        content_type: 'product',
        quantity: 1,
        description: name,
        content_id: id,
        currency: 'EUR',
        value: 1,
      })
    }
  }

  useEffect(() => {
    fbPixelCallback()
    ga4Callback()
    tiktokPixelCallback()
  }, [])

  useEffect(() => {
    if (eventMetadata) {
      window.prerenderReady = true
    }
  }, [eventMetadata])

  useEffect(() => {
    window.prerenderReady = false
  }, [])

  return (
    <>
      <Head>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />

        <meta name="robots" content={robots} />

        <title>{title}</title>

        {/*Start Smartsupp Live Chat script */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
                var _smartsupp = _smartsupp || {};
                _smartsupp.key = '8658b0df6cc57d5d00b720c9d636494096d3685e';
                window.smartsupp||(function(d) {
                var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                c.type='text/javascript';c.charset='utf-8';c.async=true;
                c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
            })(document);
            `,
          }}
        />

        {/* Inserimento dati strutturati */}
      </Head>

      {eventMetadata && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(eventMetadata)}`,
          }}
        />
      )}

      <MetaOGImages path={`/events/${codeUrl}`} title={title} />
    </>
  )
}

export default EventHead

import css from './EventListItem.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionEvent } from 'meta/pages/collection'
import { Routes } from 'meta/routes'
import { Dates } from 'utils/dates'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import Img from 'components/Media/Img'

const EventListItem: React.FC<CollectionEvent> = (props) => {
  const { coverImages, dateEvent_tz, name, priceFrom, codeUrl } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        codeUrl && navigate(Routes.Event.generatePath({ codeUrl }))
      }}
      className={css.eventListItemContainer}
    >
      <div className={css.infoContainer}>
        <img src={coverImages[0]?.url} className={css.image} />

        <div className={css.details}>
          <h3 className={css.datetime}>{Dates.format(dateEvent_tz, t('dates:common:collection'))}</h3>
          <div className={css.name}>{name}</div>
          <div className={css.priceFrom}>
            {t('collection:price_from')}
            <span>{priceFrom.formatted}</span>
          </div>
        </div>
      </div>

      {codeUrl && (
        <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
          <div className={css.action}>
            <ButtonWhiteBlack
              className={css.button}
              onClick={() => {
                navigate(Routes.Event.generatePath({ codeUrl }))
              }}
            >
              <h5>{t('collection:see_event')}</h5> <Img alt="" src="/icons/arrow_right.svg" />
            </ButtonWhiteBlack>
          </div>
        </MediaQuery>
      )}
    </div>
  )
}

export default EventListItem

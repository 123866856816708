import { FormFieldType, IFormField } from 'meta/form'
import { ITicketCovidAppointment } from 'meta/pages/tickets'

import { IFormOnSuccess, IFormValues } from 'components/Forms/Form'

export enum PersonalDataField {
  dateOfBirth = 'dateOfBirth',
  birthplaceCountry = 'birthplaceCountry',
  birthplaceCity = 'birthplaceCity',
  gender = 'gender',
}

export const PERSONAL_DATA_FIELDS = [
  PersonalDataField.dateOfBirth,
  PersonalDataField.birthplaceCountry,
  PersonalDataField.birthplaceCity,
  PersonalDataField.gender,
]

export interface ICovid19Field {
  appointment: ITicketCovidAppointment
  hiddenFieldId: string
  formField: IFormField<FormFieldType>
}

export interface ITicketsFormFields {
  commonFields: Record<string, Array<IFormField<FormFieldType>>>
  extraFields: Record<string, Array<IFormField<FormFieldType>>>
  covid19Fields: Record<string, Array<ICovid19Field> | undefined>
  personalDataFields: Record<string, Array<IFormField<FormFieldType>>>
}

export interface ITicketFormItem {
  additionalInfos: Array<IPurchaseFormDataItemsAdditionalInfo>
  appointments?: Array<ITicketCovidAppointment>
  personalData?: IPurchaseFormPersonalData
  buyerData: IPurchaseFormBuyerData
  typeTicketId: number
}

export interface ITicketsForm {
  bodyParser(fieldValues: IFormValues): object
  onSuccess: IFormOnSuccess
}

export interface IPurchaseFormBuyerData {
  quantity?: number
  firstName?: string
  lastName?: string
  phone?: string
  phonePrefix: string
  seatId?: string
}

export interface IPurchaseFormPersonalData {
  dateOfBirth: string
  gender: IPersonalDataGender
  birthplaceCountry: string
  birthplaceCity: string
}

export enum IPersonalDataGender {
  male = 'male',
  female = 'female',
}

export interface IPurchaseFormDataItemsAdditionalInfo {
  id: number
  value: string
}

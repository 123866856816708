import css from './RecapBox.module.scss'
import React, { ReactElement, useEffect, useRef } from 'react'
import { isMobileSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import {
  CardGateway,
  CheckoutAuthType,
  Checkouts,
  CheckoutStep,
  ICheckoutPayment,
  PaymentMethod,
} from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import Desktop from 'components/Checkout/RecapBox/Desktop'
import Mobile from 'components/Checkout/RecapBox/Mobile'
import Timer from 'components/Checkout/RecapBox/Timer'
import { useUserData } from 'hooks/useAuth'
import { useCheckoutCart, useCheckoutCartTickets, useCheckoutPayment, useCheckoutStripe } from 'hooks/useCheckout'

const RecapBox: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const cart = useCheckoutCart()
  const cartTickets = useCheckoutCartTickets()
  const { methodCurrent, cardGateway, couponCode } = useCheckoutPayment() as ICheckoutPayment
  const stripe = useCheckoutStripe()
  const userData = useUserData()

  const timerCompRef = useRef<ReactElement>(<Timer />)
  const continueCallback = () => {
    if (methodCurrent === PaymentMethod.none) {
      alert(t('checkout:selectPaymentMethod'))
      return
    }

    if (userData) {
      dispatch(CheckoutActions.updateAuth({ auth: { type: CheckoutAuthType.login } }))
    }

    if (methodCurrent === PaymentMethod.diciottoApp && couponCode === '') {
      alert(t('checkout:insert_18App_code'))
      return
    }

    if (methodCurrent === PaymentMethod.creditCard && !stripe?.paymentMethod && cardGateway === CardGateway.stripe) {
      alert(t('checkout:insert_ccdata'))
      return
    }

    const step = userData ? CheckoutStep.transaction : CheckoutStep.auth
    dispatch(CheckoutActions.updateStep({ step }))
  }

  useEffect(() => {
    dispatch(CheckoutActions.fetchRecap())
  }, [dispatch, cartTickets])

  if (Checkouts.getCartQuantity(cart) <= 0) {
    return null
  }

  return (
    <>
      <MediaQuery
        maxWidth={BreakpointsMaxWidth.mobile}
        className={classNames(css.recapSticky, { [css.mobileSafari]: isMobileSafari })}
      >
        <Mobile timerComponent={timerCompRef.current} continueCallback={continueCallback} />
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Desktop timerComponent={timerCompRef.current} continueCallback={continueCallback} />
      </MediaQuery>
    </>
  )
}

export default RecapBox
